<bit-form-control inline>
  <input
    type="radio"
    bitRadio
    [id]="inputId"
    [name]="name"
    [disabled]="groupDisabled || disabled"
    [value]="value"
    [checked]="selected"
    (change)="onInputChange()"
    (blur)="onBlur()"
  />
  <ng-content select="bit-label" ngProjectAs="bit-label"></ng-content>
  <ng-content select="bit-hint" ngProjectAs="bit-hint"></ng-content>
</bit-form-control>
