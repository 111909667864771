<div class="tw-pb-6 tw-pl-6">
  <p class="tw-text-xl">{{ "trialThankYou" | i18n : orgLabel }}</p>
  <ul class="tw-list-disc">
    <li>
      <p>
        {{ "trialConfirmationEmail" | i18n }}
        <span class="tw-font-bold">{{ email }}</span
        >.
      </p>
    </li>
    <li>
      <p>
        {{ "trialPaidInfoMessage" | i18n : orgLabel }}
      </p>
    </li>
  </ul>
</div>
