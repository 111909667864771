<ng-container *ngIf="label">
  <fieldset>
    <legend class="tw-mb-1 tw-block tw-text-sm tw-font-semibold tw-text-main">
      <ng-content select="bit-label"></ng-content>
    </legend>
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </fieldset>
</ng-container>

<ng-container *ngIf="!label">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</ng-container>

<ng-template #content><ng-content></ng-content></ng-template>
