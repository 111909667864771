<div class="mt-5 d-flex justify-content-center" *ngIf="loading">
  <div>
    <img class="mb-4 logo logo-themed" alt="Bitwarden" />
    <p class="text-center">
      <i
        class="bwi bwi-spinner bwi-spin bwi-2x text-muted"
        title="{{ 'loading' | i18n }}"
        aria-hidden="true"
      ></i>
      <span class="sr-only">{{ "loading" | i18n }}</span>
    </p>
  </div>
</div>
<div class="container" *ngIf="!loading">
  <div class="row justify-content-md-center mt-5">
    <div class="col-5">
      <p class="lead text-center mb-4">{{ "removeMasterPassword" | i18n }}</p>
      <hr />
      <div class="card d-block">
        <div class="card-body">
          <p>{{ "convertOrganizationEncryptionDesc" | i18n : organization.name }}</p>

          <button
            type="button"
            class="btn btn-primary btn-block"
            (click)="convert()"
            [disabled]="actionPromise"
          >
            <i
              class="bwi bwi-spinner bwi-spin"
              title="{{ 'loading' | i18n }}"
              aria-hidden="true"
              *ngIf="continuing"
            ></i>
            {{ "removeMasterPassword" | i18n }}
          </button>
          <button
            type="button"
            class="btn btn-outline-secondary btn-block"
            (click)="leave()"
            [disabled]="actionPromise"
          >
            <i
              class="bwi bwi-spinner bwi-spin"
              title="{{ 'loading' | i18n }}"
              aria-hidden="true"
              *ngIf="leaving"
            ></i>
            {{ "leaveOrganization" | i18n }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
