<bit-dialog>
  <span bitDialogTitle>
    {{ "passwordHistory" | i18n }}
  </span>
  <span bitDialogContent>
    <bit-table *ngIf="history.length">
      <ng-template body>
        <tr bitRow *ngFor="let h of history">
          <td bitCell>
            <bit-color-password
              [password]="h.password"
              class="tw-block tw-font-mono"
              [appCopyText]="h.password"
            ></bit-color-password>
            <small bitTypography="body2" class="tw-text-muted">
              {{ h.date | date : "medium" }}
            </small>
          </td>
          <td bitCell class="tw-w-0">
            <button
              type="button"
              bitIconButton="bwi-clone"
              (click)="copy(h.password)"
              [appA11yTitle]="'copyPassword' | i18n"
            ></button>
          </td>
        </tr>
      </ng-template>
    </bit-table>
    <div *ngIf="!history.length">
      {{ "noPasswordsInList" | i18n }}
    </div>
  </span>
  <ng-container bitDialogFooter>
    <button type="button" bitButton buttonType="secondary" bitDialogClose>
      {{ "close" | i18n }}
    </button>
    <button
      type="button"
      class="tw-ml-auto"
      bitIconButton="bwi-trash"
      buttonType="danger"
      title="{{ 'clear' | i18n }}"
      [bitAction]="clear"
    ></button>
  </ng-container>
</bit-dialog>
