<cdk-virtual-scroll-viewport [itemSize]="RowHeight" scrollWindow class="tw-pb-8">
  <bit-table [dataSource]="dataSource" layout="fixed">
    <ng-container header>
      <tr>
        <th bitCell class="tw-w-24 tw-whitespace-nowrap" colspan="2">
          <input
            class="tw-mr-2"
            type="checkbox"
            bitCheckbox
            id="checkAll"
            [disabled]="disabled || isEmpty"
            (change)="$event ? toggleAll() : null"
            [checked]="selection.hasValue() && isAllSelected"
          />
          <label class="tw-mb-0 !tw-font-bold !tw-text-muted" for="checkAll">{{
            "all" | i18n
          }}</label>
        </th>
        <th bitCell [class]="showExtraColumn ? 'tw-w-3/5' : 'tw-w-full'">{{ "name" | i18n }}</th>
        <th bitCell class="tw-w-2/5" *ngIf="showOwner">{{ "owner" | i18n }}</th>
        <th bitCell class="tw-w-2/5" *ngIf="showCollections">{{ "collections" | i18n }}</th>
        <th bitCell class="tw-w-2/5" *ngIf="showGroups">{{ "groups" | i18n }}</th>
        <th bitCell class="tw-w-12 tw-text-right">
          <button
            [disabled]="disabled || isEmpty"
            [bitMenuTriggerFor]="headerMenu"
            bitIconButton="bwi-ellipsis-v"
            size="small"
            type="button"
            appA11yTitle="{{ 'options' | i18n }}"
          ></button>
          <bit-menu #headerMenu>
            <button *ngIf="bulkMoveAllowed" type="button" bitMenuItem (click)="bulkMoveToFolder()">
              <i class="bwi bwi-fw bwi-folder" aria-hidden="true"></i>
              {{ "moveSelected" | i18n }}
            </button>
            <button
              *ngIf="bulkMoveAllowed"
              type="button"
              bitMenuItem
              (click)="bulkMoveToOrganization()"
            >
              <i class="bwi bwi-fw bwi-arrow-circle-right" aria-hidden="true"></i>
              {{ "moveSelectedToOrg" | i18n }}
            </button>
            <button *ngIf="showBulkTrashOptions" type="button" bitMenuItem (click)="bulkRestore()">
              <i class="bwi bwi-fw bwi-undo" aria-hidden="true"></i>
              {{ "restoreSelected" | i18n }}
            </button>
            <button type="button" bitMenuItem (click)="bulkDelete()">
              <span class="tw-text-danger">
                <i class="bwi bwi-fw bwi-trash" aria-hidden="true"></i>
                {{ (showBulkTrashOptions ? "permanentlyDeleteSelected" : "deleteSelected") | i18n }}
              </span>
            </button>
          </bit-menu>
        </th>
      </tr>
    </ng-container>
    <ng-template body let-rows$>
      <ng-container *cdkVirtualFor="let item of rows$; templateCacheSize: 0">
        <tr
          *ngIf="item.collection"
          bitRow
          appVaultCollectionRow
          alignContent="middle"
          [disabled]="disabled"
          [collection]="item.collection"
          [showOwner]="showOwner"
          [showCollections]="showCollections"
          [showGroups]="showGroups"
          [organizations]="allOrganizations"
          [groups]="allGroups"
          [canDeleteCollection]="canDeleteCollection(item.collection)"
          [canEditCollection]="canEditCollection(item.collection)"
          [checked]="selection.isSelected(item)"
          (checkedToggled)="selection.toggle(item)"
          (onEvent)="event($event)"
        ></tr>
        <tr
          *ngIf="item.cipher"
          bitRow
          appVaultCipherRow
          alignContent="middle"
          [disabled]="disabled"
          [cipher]="item.cipher"
          [showOwner]="showOwner"
          [showCollections]="showCollections"
          [showGroups]="showGroups"
          [showPremiumFeatures]="showPremiumFeatures"
          [useEvents]="useEvents"
          [cloneable]="canClone(item)"
          [organizations]="allOrganizations"
          [collections]="allCollections"
          [checked]="selection.isSelected(item)"
          (checkedToggled)="selection.toggle(item)"
          (onEvent)="event($event)"
        ></tr>
      </ng-container>
    </ng-template>
  </bit-table>
</cdk-virtual-scroll-viewport>
