<form [formGroup]="formGroup" [bitSubmit]="submit">
  <bit-dialog dialogSize="large" [loading]="loading$ | async">
    <span bitDialogTitle
      >{{ "removePasskey" | i18n }}
      <span *ngIf="credential" class="tw-text-sm tw-normal-case tw-text-muted">{{
        credential.name
      }}</span>
    </span>
    <ng-container bitDialogContent>
      <ng-container *ngIf="!credential">
        <i class="bwi bwi-spinner bwi-spin tw-ml-1" aria-hidden="true"></i>
      </ng-container>

      <ng-container *ngIf="credential">
        <p bitTypography="body1">{{ "removePasskeyInfo" | i18n }}</p>

        <bit-form-field disableMargin>
          <bit-label>{{ "masterPassword" | i18n }}</bit-label>
          <input type="password" bitInput formControlName="masterPassword" />
          <button type="button" bitIconButton bitSuffix bitPasswordInputToggle></button>
          <bit-hint>{{ "confirmIdentity" | i18n }}</bit-hint>
        </bit-form-field>
      </ng-container>
    </ng-container>
    <ng-container bitDialogFooter>
      <button type="submit" bitButton bitFormButton buttonType="danger">
        {{ "remove" | i18n }}
      </button>
      <button type="button" bitButton bitFormButton buttonType="secondary" bitDialogClose>
        {{ "cancel" | i18n }}
      </button>
    </ng-container>
  </bit-dialog>
</form>
