<div class="page-header">
  <h1>{{ "preferences" | i18n }}</h1>
</div>
<p>{{ "preferencesDesc" | i18n }}</p>
<form [formGroup]="form" (ngSubmit)="submit()" ngNativeValidate>
  <div class="row">
    <div class="col-6">
      <app-callout type="info" *ngIf="vaultTimeoutPolicyCallout | async as policy">
        <span *ngIf="policy.timeout && policy.action">
          {{
            "vaultTimeoutPolicyWithActionInEffect"
              | i18n : policy.timeout.hours : policy.timeout.minutes : (policy.action | i18n)
          }}
        </span>
        <span *ngIf="policy.timeout && !policy.action">
          {{ "vaultTimeoutPolicyInEffect" | i18n : policy.timeout.hours : policy.timeout.minutes }}
        </span>
        <span *ngIf="!policy.timeout && policy.action">
          {{ "vaultTimeoutActionPolicyInEffect" | i18n : (policy.action | i18n) }}
        </span>
      </app-callout>
      <app-vault-timeout-input
        [vaultTimeoutOptions]="vaultTimeoutOptions"
        [formControl]="form.controls.vaultTimeout"
        ngDefaultControl
      >
      </app-vault-timeout-input>
    </div>
  </div>
  <ng-container *ngIf="availableVaultTimeoutActions$ | async as availableVaultTimeoutActions">
    <div *ngIf="availableVaultTimeoutActions.length > 1" class="form-group">
      <label>{{ "vaultTimeoutAction" | i18n }}</label>
      <div
        *ngIf="availableVaultTimeoutActions.includes(VaultTimeoutAction.Lock)"
        class="form-check form-check-block"
      >
        <input
          class="form-check-input"
          type="radio"
          name="vaultTimeoutAction"
          id="vaultTimeoutActionLock"
          value="{{ VaultTimeoutAction.Lock }}"
          formControlName="vaultTimeoutAction"
        />
        <label class="form-check-label" for="vaultTimeoutActionLock">
          {{ "lock" | i18n }}
          <small>{{ "vaultTimeoutActionLockDesc" | i18n }}</small>
        </label>
      </div>
      <div
        *ngIf="availableVaultTimeoutActions.includes(VaultTimeoutAction.LogOut)"
        class="form-check mt-2 form-check-block"
      >
        <input
          class="form-check-input"
          type="radio"
          name="vaultTimeoutAction"
          id="vaultTimeoutActionLogOut"
          value="{{ VaultTimeoutAction.LogOut }}"
          formControlName="vaultTimeoutAction"
        />
        <label class="form-check-label" for="vaultTimeoutActionLogOut">
          {{ "logOut" | i18n }}
          <small>{{ "vaultTimeoutActionLogOutDesc" | i18n }}</small>
        </label>
      </div>
    </div>
  </ng-container>
  <div class="row">
    <div class="col-6">
      <div class="form-group">
        <div class="d-flex">
          <label for="locale">{{ "language" | i18n }}</label>
          <a
            class="ml-auto"
            href="https://bitwarden.com/help/localization/"
            target="_blank"
            rel="noopener"
            appA11yTitle="{{ 'learnMore' | i18n }}"
          >
            <i class="bwi bwi-question-circle" aria-hidden="true"></i>
          </a>
        </div>
        <select id="locale" name="Locale" formControlName="locale" class="form-control">
          <option *ngFor="let o of localeOptions" [ngValue]="o.value">{{ o.name }}</option>
        </select>
        <small class="form-text text-muted">{{ "languageDesc" | i18n }}</small>
      </div>
    </div>
  </div>
  <div class="form-group">
    <div class="form-check">
      <input
        class="form-check-input"
        type="checkbox"
        id="enableFavicons"
        name="enableFavicons"
        formControlName="enableFavicons"
      />
      <label class="form-check-label" for="enableFavicons">
        {{ "enableFavicon" | i18n }}
      </label>
      <a
        href="https://bitwarden.com/help/website-icons/"
        target="_blank"
        rel="noopener"
        appA11yTitle="{{ 'learnMore' | i18n }}"
      >
        <i class="bwi bwi-question-circle" aria-hidden="true"></i>
      </a>
    </div>
    <small class="form-text text-muted">{{ "faviconDesc" | i18n }}</small>
  </div>
  <div class="form-group">
    <div class="form-check">
      <input
        class="form-check-input"
        type="checkbox"
        id="enableFullWidth"
        name="enableFullWidth"
        formControlName="enableFullWidth"
      />
      <label class="form-check-label" for="enableFullWidth">
        {{ "enableFullWidth" | i18n }}
      </label>
    </div>
    <small class="form-text text-muted">{{ "enableFullWidthDesc" | i18n }}</small>
  </div>
  <div class="row">
    <div class="col-6">
      <div class="form-group">
        <label for="theme">{{ "theme" | i18n }}</label>
        <select id="theme" name="theme" formControlName="theme" class="form-control">
          <option *ngFor="let o of themeOptions" [ngValue]="o.value">{{ o.name }}</option>
        </select>
        <small class="form-text text-muted">{{ "themeDesc" | i18n }}</small>
      </div>
    </div>
  </div>
  <button type="submit" class="btn btn-primary">
    {{ "save" | i18n }}
  </button>
</form>
