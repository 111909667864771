<div class="card vault-filters">
  <div class="container loading-spinner" *ngIf="!isLoaded">
    <i class="bwi bwi-spinner bwi-spin bwi-3x" aria-hidden="true"></i>
  </div>
  <div *ngIf="isLoaded">
    <div class="card-header d-flex">
      {{ "filters" | i18n }}
      <a
        class="ml-auto"
        href="https://bitwarden.com/help/searching-vault/"
        target="_blank"
        rel="noopener"
        appA11yTitle="{{ 'learnMore' | i18n }}"
      >
        <i class="bwi bwi-question-circle" aria-hidden="true"></i>
      </a>
    </div>
    <div class="card-body">
      <input
        type="search"
        placeholder="{{ searchPlaceholder | i18n }}"
        id="search"
        class="form-control"
        [ngModel]="searchText"
        (ngModelChange)="onSearchTextChanged($event)"
        autocomplete="off"
        appAutofocus
      />
      <ng-container *ngFor="let f of filtersList">
        <div class="filter">
          <app-filter-section [activeFilter]="activeFilter" [section]="f"> </app-filter-section>
        </div>
      </ng-container>
    </div>
  </div>
</div>
