<bit-dialog>
  <span bitDialogTitle>
    {{ "moveSelectedToOrg" | i18n }}
  </span>
  <span bitDialogContent>
    <p>{{ "moveManyToOrgDesc" | i18n }}</p>
    <p>
      {{
        "moveSelectedItemsCountDesc"
          | i18n : this.ciphers.length : shareableCiphers.length : nonShareableCount
      }}
    </p>
    <bit-form-field>
      <bit-label for="organization">{{ "organization" | i18n }}</bit-label>
      <select
        bitInput
        [(ngModel)]="organizationId"
        id="organization"
        (change)="filterCollections()"
      >
        <option *ngFor="let o of organizations" [ngValue]="o.id">{{ o.name }}</option>
      </select>
    </bit-form-field>

    <div class="d-flex">
      <label class="tw-mb-1 tw-block tw-font-semibold tw-text-main">{{
        "collections" | i18n
      }}</label>
      <div class="tw-ml-auto tw-flex tw-gap-2" *ngIf="collections && collections.length">
        <button bitLink type="button" (click)="selectAll(true)" class="tw-px-2">
          {{ "selectAll" | i18n }}
        </button>
        <button bitLink type="button" (click)="selectAll(false)" class="tw-px-2">
          {{ "unselectAll" | i18n }}
        </button>
      </div>
    </div>
    <div *ngIf="!collections || !collections.length">
      {{ "noCollectionsInList" | i18n }}
    </div>
    <table
      class="table table-hover table-list mb-0"
      *ngIf="collections && collections.length"
      id="collections"
    >
      <tbody>
        <tr *ngFor="let c of collections; let i = index" (click)="check(c)">
          <td class="table-list-checkbox">
            <input
              bitInput
              type="checkbox"
              [(ngModel)]="c.checked"
              name="Collection[{{ i }}].Checked"
              attr.aria-label="Check {{ c.name }}"
              appStopProp
            />
          </td>
          <td>
            {{ c.name }}
          </td>
        </tr>
      </tbody>
    </table>
  </span>
  <ng-container bitDialogFooter>
    <button bitButton type="submit" buttonType="primary" [bitAction]="submit">
      {{ "save" | i18n }}
    </button>
    <button bitButton type="button" buttonType="secondary" (click)="cancel()">
      {{ "cancel" | i18n }}
    </button>
  </ng-container>
</bit-dialog>
